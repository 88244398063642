import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PricingPage from '../../../profiles/components/PricingPage';

const PricingMenu = ({ isOpen }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{opacity: 0, y: -20}}
        animate={{opacity: 1, y: 0}}
        exit={{opacity: 0, y: -20}}
        className="absolute top-20 inset-x-0 mx-4 bg-white rounded-lg shadow-lg z-40"
      >
        <div className="p-6">
          <PricingPage />
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);

export default PricingMenu;