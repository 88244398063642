import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => (
  <Link to="/" className="inline-flex items-center leading-none">
    <img src="/static/assets/logo.svg" alt="Logo" className="h-8 flex-shrink-0 translate-y-[1px]"/>
    <span className="text-3xl font-black font-barlow ml-3 italic leading-none translate-y-[-2px]">
      uzplaylists
    </span>
  </Link>
);

export default Logo;