import React, { useState, useRef } from 'react';
import Logo from './components/NavbarCore/Logo';
import NavigationLinks from './components/NavbarCore/NavigationLinks';
import MenuButton from './components/NavbarButtons/MenuButtons';
import AuthButtons from './components/NavbarButtons/AuthButtons';
import UserMenu from './components/NavbarButtons/UserMenu';
import LoginMenu from './components/NavbarDropdowns/LoginMenu';
import PricingMenu from './components/NavbarDropdowns/PricingMenu';
import MobileMenu from './components/NavbarDropdowns/MobileMenu';

const Navbar = () => {
 const [activeMenu, setActiveMenu] = useState(null);
 const [showUserMenu, setShowUserMenu] = useState(false);
 const userMenuRef = useRef(null);
 const [isAuthenticated, setIsAuthenticated] = useState(
   window.djangoContext?.isAuthenticated || false
 );

 const openMenu = (menuName) => {
   setActiveMenu(activeMenu === menuName ? null : menuName);
 };

 return (
   <>
     <MobileMenu
       isOpen={activeMenu === 'mobile'}
       isAuthenticated={isAuthenticated}
       setActiveMenu={setActiveMenu}
     />
     <LoginMenu isOpen={activeMenu === 'login'} />
     <PricingMenu isOpen={activeMenu === 'pricing'} />

     <div className="sticky top-4 max-w-7xl mx-auto px-4 relative z-50">
       <div className="bg-white border rounded-full shadow-sm px-6">
         <div className="flex items-center justify-between h-16">
           <div className="flex items-center gap-8">
             <Logo />
             <NavigationLinks />
           </div>

             <div className="flex items-center">
                 {isAuthenticated ? (
                     <UserMenu
                         isOpen={showUserMenu}
                         onToggle={() => setShowUserMenu(!showUserMenu)}
                         onClose={() => setShowUserMenu(false)}
                     />
                 ) : (
                     <AuthButtons
                         onLoginClick={() => openMenu('login')}
                         onPricingClick={() => openMenu('pricing')}
                     />
                 )}

                 <div className="flex items-center md:hidden ml-4">
                     {!isAuthenticated && (
                         <button
                             onClick={() => openMenu('login')}
                             className="text-sm font-medium mr-4"
                         >
                             Sign In
                         </button>
                     )}
                     <MenuButton
                         isOpen={activeMenu === 'mobile'}
                         onClick={() => openMenu('mobile')}
                     />
                 </div>
             </div>
         </div>
       </div>
     </div>
   </>
 );
};

export default Navbar;