import React, { useState } from 'react';
import { FaCheck, FaDiscord } from 'react-icons/fa';
import Modal from '../../../components/Modal';
import LoginPrompt from './LogInPrompt';

const PricingPage = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleJoin = async (plan) => {
    try {
      const response = await fetch('/api/payments/memberships/join/', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': window.djangoContext.csrfToken
        },
        body: JSON.stringify({ plan })
      });

      const data = await response.json();
      console.log(data)

      if (!response.ok) {
        throw new Error(data.error || 'Failed to process membership');
      }

      if (!data.authenticated) {
        setShowLoginModal(true);

      } else if (data.checkout_url) {
        window.location.href = data.checkout_url;
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const plans = {
    free: {
      title: 'Free',
      description: 'Basic features for everyone.',
      price: '0',
      buttonText: 'Sign up with Discord',
      features: [
        { text: 'Standard Submission Voting', icon: <FaCheck className="text-blue-500" /> },
        { text: 'Discord Access', icon: <FaDiscord className="text-blue-500" /> }
      ]
    },
    musiclover: {
      title: 'Music Lover',
      description: 'For the music enthusiasts.',
      price: '5',
      features: [
        { text: '1x Playlist Voting Power', icon: <FaCheck className="text-blue-500" /> },
        { text: '3x Submission Voting Power', icon: <FaCheck className="text-blue-500" /> },
        { text: 'Access to Discovery Playlist Archives', icon: <FaCheck className="text-blue-500" /> },
        { text: 'Discord Access', icon: <FaDiscord className="text-blue-500" /> },
        { text: 'Support uzplaylists!', icon: <img src="/static/assets/logo.svg" alt="logo" className="w-4 h-4" /> }
      ]
    },
    artist: {
      title: 'Artist',
      description: 'For artists looking to get heard.',
      price: '10',
      features: [
        { text: '1 Free Standard Submission/Month', icon: <FaCheck className="text-blue-500" /> },
        { text: '3x Playlist Voting Power', icon: <FaCheck className="text-blue-500" /> },
        { text: '5x Submission Voting Power', icon: <FaCheck className="text-blue-500" /> },
        { text: 'Access to Discovery Playlist Archives', icon: <FaCheck className="text-blue-500" /> },
        { text: 'Discord Access', icon: <FaDiscord className="text-blue-500" /> },
        { text: 'Support uzplaylists!', icon: <img src="/static/assets/logo.svg" alt="logo" className="w-4 h-4" /> }
      ]
    },
    curator: {
      title: 'Curator',
      description: 'For the true tastemakers.',
      price: '20',
      features: [
        { text: '1 Free Express Submission/Month', icon: <FaCheck className="text-blue-500" /> },
        { text: '5x Playlist Voting Power', icon: <FaCheck className="text-blue-500" /> },
        { text: '7x Submission Voting Power', icon: <FaCheck className="text-blue-500" /> },
        { text: 'Access to Discovery Playlist Archives', icon: <FaCheck className="text-blue-500" /> },
        { text: 'Discord Access', icon: <FaDiscord className="text-blue-500" /> },
        { text: 'Support uzplaylists!', icon: <img src="/static/assets/logo.svg" alt="logo" className="w-4 h-4" /> }
      ]
    }
  };

  const MembershipPlan = ({ title, description, price, features, onJoin, buttonText }) => (
    <div className="group h-full rounded-lg shadow-sm overflow-hidden border-2 border-black transition-transform duration-300 ease-in-out hover:-translate-y-1 hover:shadow-lg">
      <div className="bg-blue-700 p-4">
        <h2 className="text-xl font-black text-white">{title}</h2>
        <p className="text-xs text-white mb-2">{description}</p>
        <p className="text-3xl font-bold text-white mb-4">
          ${price}<span className="text-lg font-normal">/mo</span>
        </p>
        <button
          onClick={onJoin}
          className="w-full bg-blue-500 text-white rounded-md py-2 text-sm font-black hover:bg-blue-600 transition duration-300"
        >
          {buttonText || 'Join'}
        </button>
      </div>
      <div className="bg-white p-4 h-full border-black border-t">
        <ul className="text-sm text-gray-600">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start mb-2">
              <span className="w-4 h-4 mr-2 flex-shrink-0">{feature.icon}</span>
              <span>{feature.text}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  return (
    <>
      <div className="w-full min-h-screen py-32 flex items-start md:items-center justify-center">
        <div className="max-w-7xl mx-auto px-4 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-9 gap-2 md:gap-1 items-stretch">
            <div className="md:col-span-2 flex flex-col h-full">
              <h2 className="font-medium text-2xl italic mb-2 flex-nowrap">
                Sign in for playlist voting
              </h2>
              <MembershipPlan {...plans.free} onJoin={() => handleJoin('free')} />
            </div>

            <div className="flex items-center justify-center pt-14 pb-2 md:col-span-1">
              <span className="text-xl font-bold">or</span>
            </div>

            <div className="md:col-span-6">
              <div className="text-center flex flex-col md:flex-row md:items-end justify-center md:pl-16 mb-2">
                <h2 className="font-medium text-2xl italic">
                  become a member for exclusive perks
                </h2>
                <p className="text-sm text-gray-600 md:pl-4">it's like a patreon!</p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                {['musiclover', 'artist', 'curator'].map((planKey) => (
                  <div key={planKey}>
                    <MembershipPlan
                      {...plans[planKey]}
                      onJoin={() => handleJoin(planKey)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        title="Sign In Required"
      >
        <LoginPrompt message="Please sign in with Discord to purchase a membership" />
      </Modal>
    </>
  );
};

export default PricingPage;