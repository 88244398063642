import React from 'react';
import { Link } from 'react-router-dom';

const NavigationLinks = () => {
  const links = [
    {text: "Playlists", path: "/playlists"},
    {text: "Submissions", path: "/submissions"},
    {text: "Discord", path: "https://discord.gg/D3ngmymWQe", isExternal: true},
    {text: "Shop", path: "/shop"}
  ];

  return (
    <nav className="hidden md:flex gap-6">
      {links.map(link => (
        link.isExternal ? (
          <a
            key={link.path}
            href={link.path}
            target="_blank"
            rel="noopener noreferrer"
            className="font-barlow-black text-xl"
          >
            {link.text}
          </a>
        ) : (
          <Link
            key={link.path}
            to={link.path}
            className="font-barlow-black text-xl"
          >
            {link.text}
          </Link>
        )
      ))}
    </nav>
  );
};

export default NavigationLinks;