import React from 'react';
import { motion } from 'framer-motion';

const MenuButton = ({isOpen, onClick}) => (
  <motion.button onClick={onClick} className="md:hidden w-6 h-6 relative">
    <svg viewBox="0 0 24 24">
      <motion.path
        fill="transparent"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        initial={false}
        animate={isOpen ? {d: "M18 6L6 18M6 6l12 12"} : {d: "M4 6h16M4 12h16M4 18h16"}}
      />
    </svg>
  </motion.button>
);

export default MenuButton;