import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {Link} from 'react-router-dom';

const MenuItem = ({link, i}) => {
  const baseClass = "py-2 font-barlow-black text-2xl";
  const specialClass = "w-[calc(100%-3rem)] mx-auto mb-2 bg-blue-600 text-white rounded-lg text-center hover:bg-blue-700";
  const normalClass = "px-6 hover:bg-gray-50";

  const className = `block ${baseClass} ${link.special ? specialClass : normalClass}`;

  if (link.isExternal) {
    return (
      <a href={link.path} target="_blank" rel="noopener noreferrer" className={className}>
        {link.text}
      </a>
    );
  }

  if (link.onClick) {
    return <button onClick={link.onClick} className={className}>{link.text}</button>;
  }

  return <Link to={link.path} className={className}>{link.text}</Link>;
};

const MobileMenu = ({isOpen, isAuthenticated, setActiveMenu}) => {
    const links = [
        ...(isAuthenticated
            ? [{text: "Profile", path: "/profile"}]
            : [{text: "Become A Patron", onClick: () => setActiveMenu('pricing'), special: true}]),
        {text: "Playlists", path: "/playlists"},
        {text: "Submissions", path: "/submissions"},
        {text: "Discord", path: "https://discord.gg/D3ngmymWQe", isExternal: true},
        {text: "Shop", path: "/shop"}
    ];

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{opacity: 0, y: -20}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0, y: -20}}
                    className="absolute top-20 inset-x-0 mx-4 bg-white rounded-lg shadow-lg z-40"
                >
                    <nav className="py-4">
                        {links.map((link, i) => (
                            <motion.div
                                key={link.path || i}
                                initial={{x: -20, opacity: 0}}
                                animate={{x: 0, opacity: 1}}
                                transition={{delay: i * 0.05}}
                            >
                                <MenuItem link={link} i={i} />
                            </motion.div>
                        ))}
                    </nav>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default MobileMenu;