import React, { useEffect } from 'react';

const LoginPrompt = ({ message = "Please log in to continue" }) => {
 const handleDiscordLogin = () => {
   const currentPath = window.location.pathname;
   window.location.href = `/oauth/login/discord/?next=${encodeURIComponent(currentPath)}`;
 };

 const handleTokenRedirect = async () => {
   const urlParams = new URLSearchParams(window.location.search);
   const discordToken = urlParams.get('code');

   if (discordToken) {
     try {
       const response = await fetch('/auth/convert-token/', {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify({
           grant_type: 'convert_token',
           client_id: '<your-drf-client-id>',
           client_secret: '<your-drf-client-secret>',
           backend: 'discord',
           token: discordToken,
         }),
       });

       const data = await response.json();

       if (response.ok) {
         localStorage.setItem('authToken', data.access_token);
         window.location.href = '/';
       } else {
         console.error('Token exchange failed:', data);
         alert('Failed to log in. Please try again.');
       }
     } catch (error) {
       console.error('An error occurred:', error);
     }
   }
 };

 useEffect(() => {
   handleTokenRedirect();
 }, []);

 return (
   <div className="p-8">
     <h2 className="text-2xl font-barlow-black italic mb-6">{message}</h2>
     <button
       onClick={handleDiscordLogin}
       className="bg-[#5865F2] text-white px-8 py-3 rounded-2xl font-barlow-black text-xl hover:bg-[#4752C4] transition-all transform hover:scale-105"
     >
       Login with Discord
     </button>
   </div>
 );
};

export default LoginPrompt;

