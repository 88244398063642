import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import LoginPrompt from '../../../profiles/components/LogInPrompt';

const LoginMenu = ({ isOpen }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{opacity: 0, y: -20}}
        animate={{opacity: 1, y: 0}}
        exit={{opacity: 0, y: -20}}
        className="absolute top-20 inset-x-0 mx-4 bg-white rounded-lg shadow-lg z-40"
      >
        <LoginPrompt message="Please sign in with Discord to continue"/>
      </motion.div>
    )}
  </AnimatePresence>
);

export default LoginMenu;