import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './feature/navbar/Navbar';

// Lazy load pages
const HomePage = lazy(() => import('./feature/home/components/HomePage'));
const SubmissionForm = lazy(() => import('./feature/submissions/components/SubmissionForm'));
const SubmissionList = lazy(() => import('./feature/voting/components/SubmissionVoting'));
const PricingPage = lazy(() => import('./feature/profiles/components/PricingPage'));
const ProfilePage = lazy(() => import('./feature/profiles/components/ProfilePage'));
const PlaylistPage = lazy(() => import('./feature/playlists/components/PlaylistPage'));
const ShopPage = lazy(() => import('./feature/shop/components/ShopPage'));

// Wrapper component for Profile with payment status check
const ProfileWrapper = lazy(() => import('./feature/profiles/components/ProfileWrapper'));

function App() {
  const excludeBackendRoutes = ['/admin', '/auth', '/oauth'];

  // Do not render React Router if the path starts with backend routes
  const isBackendRoute = excludeBackendRoutes.some((route) =>
    window.location.pathname.startsWith(route)
  );

  if (isBackendRoute) {
    return null; // Let Django handle the route
  }

  return (
    <BrowserRouter>
      <div className="app">
        <Navbar />
        <main>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/submissions" element={<SubmissionForm />} />
              <Route path="/vote" element={<SubmissionList />} />
              <Route path="/membership" element={<PricingPage />} />
              <Route path="/profile" element={<ProfileWrapper />} />
              <Route path="/playlists" element={<PlaylistPage />} />
              <Route path="/shop" element={<ShopPage />} />
            </Routes>
          </Suspense>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default App;