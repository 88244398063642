import React from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';

const UserMenu = ({ isOpen, onToggle }) => {
 const handleLogout = async () => {
   try {
     const response = await fetch('/api/members/logout/', {
       method: 'POST',
       credentials: 'include',
       headers: {
         'X-CSRFToken': window.djangoContext.csrfToken,
         'Content-Type': 'application/json'
       },
     });
     if (response.ok) {
       window.location.href = '/';
     }
   } catch (error) {
     console.error('Logout error:', error);
   }
 };

 return (
   <div className="relative">
     <FaUserCircle
       className="w-6 h-6 cursor-pointer"
       onClick={onToggle}
     />
     {isOpen && (
       <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
         <Link to="/profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
           Profile
         </Link>
         <button onClick={handleLogout} className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
           Sign Out
         </button>
       </div>
     )}
   </div>
 );
};

export default UserMenu;