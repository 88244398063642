import React from 'react';
import { Link } from 'react-router-dom';

const AuthButtons = ({ onLoginClick, onPricingClick }) => (
  <div className="hidden md:flex items-center gap-4">
    <button
      onClick={onLoginClick}
      className="text-gray-700 hover:text-gray-900"
    >
      Sign In
    </button>
    <button
      onClick={onPricingClick}
      className="bg-blue-800 px-4 py-2 text-white font-barlow-black text-xl rounded-2xl hover:bg-blue-700"
    >
      Become A Member
    </button>
  </div>
);

export default AuthButtons;